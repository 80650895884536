.AppDiv {
  height: 650px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* Responsive code For default(600px) Start By Prem Singh */

@media only screen and (max-width: 600px) {
  .AppDiv {
    height: 50vh;
    background-image: url("../../BgImg/Frame.jpg") !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: auto; */
  }
}

/* For general iPad layouts Start */
@media only screen and (device-width: 768px) {
  .AppDiv {
    background-image: url("../../BgImg/iPad mini.jpg") !important;
    background-position: unset;
    background-repeat: no-repeat;
    /* background-size: auto; */
    height: 490px;
    padding: 0px 20px;
  }
}

/* For Large Screens  */

@media screen and (min-width: 1900px) {
  .AppDiv {
    background-image: url("../../BgImg/bG1900px.jpg") !important;
    height: 800px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1600px) {
}
