@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 6px;
  height:6px;
  background-color: #fff897;
}
::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 50px;
}
body {
  background-color: #ffffff !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
	-moz-appearance: textfield;
  }


.AppDiv {
  height: 650px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* Responsive code For default(600px) Start By Prem Singh */

@media only screen and (max-width: 600px) {
  .AppDiv {
    height: 50vh;
    background-image: url(/static/media/Frame.b5cb6aba.jpg) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: auto; */
  }
}

/* For general iPad layouts Start */
@media only screen and (device-width: 768px) {
  .AppDiv {
    background-image: url("/static/media/iPad mini.b6dad173.jpg") !important;
    background-position: unset;
    background-repeat: no-repeat;
    /* background-size: auto; */
    height: 490px;
    padding: 0px 20px;
  }
}

/* For Large Screens  */

@media screen and (min-width: 1900px) {
  .AppDiv {
    background-image: url(/static/media/bG1900px.f728dfb2.jpg) !important;
    height: 800px;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1600px) {
}

